
import 'photoswipe/dist/photoswipe.css'

import { useState, useEffect } from "react";

import { Gallery, Item } from 'react-photoswipe-gallery'

function PhotoGallery(props) {
    var images = []
    if ("images" in props)
        images = props.images

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });

    const updateSize = () =>
        setSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    useEffect(() => (window.onresize = updateSize), []);

    return (
        <div className="photogallery">
            <Gallery>
                {images.map(function(object, i) {
                    return <Item
                        original={object.source}
                        thumbnail={object.thumb}
                        width={object.width / object.height * 768}
                        height="768"
                    >
                        {({ ref, open }) => (
                            <img className="photogallery-image" ref={ref} onClick={open} src={object.source} />
                        )}
                    </Item>
                })}
            </Gallery>
        </div>
    )
}

export default PhotoGallery;