import AdamAndEmoryFooter from '../img/Graphics/Footer.png'

function Footer() {
    return(
        <div>
            <hr style={{marginBottom: "0px", marginTop: "1rem", padding:"0px", height:"2px"}}></hr>
            <img className="footer" src={AdamAndEmoryFooter}/>
        </div>
    )
}

export default Footer;