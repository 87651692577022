import React, { useState } from 'react';
import addDays from 'date-fns/addDays';

import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Navigation from './components/Navigation';
import Home from './components/Home';
import Story from './components/Story';
import Travel from './components/Travel';
import ThingsToDo from './components/ThingsToDo';
import Photos from './components/Photos';
import WeddingParty from './components/WeddingParty';
import Questions from './components/Questions';
import Registry from './components/Registry';
import RSVP from './components/RSVP';
import Footer from './components/Footer';

import AdamAndEmory from './img/Graphics/Header.png'

function App() {

  const [page, setPage] = useState("Home");

  const handler = (newPage) => {
    console.log("Change page to " + newPage)
    setPage(newPage);
  }

  return (
    <div className="App">
      <img className="title" src={AdamAndEmory}/>
      <Navigation handler={handler}></Navigation>
      {page === "Home" ? <Home/> : ""}
      {page === "Story" ? <Story/> : ""}
      {page === "Photos" ? <Photos/> : ""}
      {page === "WeddingParty" ? <WeddingParty/> : ""}
      {page === "Questions" ? <Questions/> : ""}
      {page === "Travel" ? <Travel/> : ""}
      {page === "ThingsToDo" ? <ThingsToDo/> : ""}
      {page === "Registry" ? <Registry/> : ""}
      {page === "RSVP" ? <RSVP/> : ""}
      <Footer/>
    </div>
  );
}

export default App;
