import cursor from '../img/cursor.cur'

function WeddingPartyElement(props) {
    const imgStyle = {
        position:"absolute",
        display: "inline",
        margin:"0 auto",
        height:"auto", 
        width:props.zoom,
        left:props.left,
        top:props.top
    }
    const goofyImgStyle = {
        position:"absolute",
        display: "inline",
        margin:"0 auto",
        height:"auto", 
        width:props.goofyzoom,
        left:props.goofyleft,
        top:props.goofytop
    }


    if (props.initgoofy) {
        imgStyle['cursor'] = 'url(' + cursor + '), auto'
        goofyImgStyle['cursor'] = 'url(' + cursor + '), auto'
    }

    return(
        <div>
            <div className="justify-content-center">
                <div style={{width: "70%", height: 0, paddingBottom: "70%", position: "relative", overflow: "hidden", borderRadius: "50%", margin: "auto"}}>
                    {!props.goofy ?
                        <img onClick={props.toggleGoofy} style={imgStyle} src={props.image}/>
                        :
                        <img onClick={props.toggleGoofy} style={goofyImgStyle} src={props.goofyimage}/>
                    }
                </div>
                <div style={{width: "100%", margin: "auto"}}>
                    <h4>{props.name}</h4>
                    <h6>{props.role}</h6>
                    <div style={{textAlign: "justify", "wordSpacing":"-2px", "textJustify": "distribute","textAlignLast": "left", "hyphens": "auto"}}>{props.description}</div>
                </div>
            </div>
            <br/>
        </div>
    )
}

export default WeddingPartyElement;