function WeddingPartyElement(props) {
    const imgStyle = {
        position:"absolute",
        display: "inline",
        margin:"0 auto",
        height:"auto", 
        width:props.zoom,
        left:props.left,
        top:props.top
    }
    const goofyImgStyle = {
        position:"absolute",
        display: "inline",
        margin:"0 auto",
        height:"auto", 
        width:props.goofyzoom,
        left:props.goofyleft,
        top:props.goofytop
    }
    return(
        <div className="wedding-party-element">
            <div className="row justify-content-center">
                <div style={{width: "32%", height: 0, paddingBottom: "32%", position: "relative", overflow: "hidden", borderRadius: "50%", marginTop: "auto", marginBottom: "auto"}}>
                    {!props.goofy ?
                        <img style={imgStyle} src={props.image}/>
                        :
                        <img style={goofyImgStyle} src={props.goofyimage}/>
                    }
                </div>
                <div style={{width: "50%", paddingLeft: "25px", paddingRight:"0"}}>
                    <h4>{props.name}</h4> 
                    <h6>{props.role}</h6>
                    <div style={{textAlign: "justify", "wordSpacing":"-2px", "textJustify": "distribute","textAlignLast": "left", "hyphens": "auto"}}>{props.description}</div>
                    <br/>
                </div>
            </div>
            <br/>
            
            <br/>
        </div>
    )
}

export default WeddingPartyElement;