import React, { useState, useEffect } from 'react';

import WeddingPartyElement from "./WeddingPartyElement";
import WeddingPartyParentElement from "./WeddingPartyParentElement";
import Adam from '../img/WeddingParty/Wedding Party/Adam NYC.jpg';
import Emory from '../img/WeddingParty/Wedding Party/Emory NYC.jpg';
import Mey from '../img/WeddingParty/Wedding Party/Mom.jpg';
import Sakshi from '../img/WeddingParty/Sakshi 1.jpg';
import Jay from '../img/WeddingParty/Wedding Party/Jay 1.jpg';
import Jay2 from '../img/WeddingParty/Wedding Party/Jay 2.jpg';
import Jay3 from '../img/WeddingParty/Wedding Party/Jay 3.jpg';
import Jay4 from '../img/WeddingParty/Wedding Party/Jay 4.jpg';
import Jay5 from '../img/WeddingParty/Wedding Party/Jay 5.jpg';
import PoePoe from '../img/WeddingParty/Easter Eggs/Poe Poe Easter Egg 2.jpg';
import Staci from '../img/WeddingParty/Wedding Party/Staci.jpg';
import EmAndYana from '../img/WeddingParty/Wedding Party/EmandYana.jpg';
import Alex from '../img/WeddingParty/Wedding Party/Alex.jpg';
import Maya from '../img/WeddingParty/MAYA NEW.jpg';
import Ariana from '../img/WeddingParty/arianaandadam5.jpg'
import Arif from '../img/WeddingParty/arifandadam6.jpg'
import Thaminda from '../img/WeddingParty/thamindaandadam3.jpg'
import Greg from '../img/WeddingParty/gregandadam.jpg'
import Rose from '../img/WeddingParty/momandadam.jpg'

import GoofyAdam from '../img/WeddingParty/adamgoofy.jpg'
import GoofyArif from '../img/WeddingParty/Easter Eggs/arifgoofy.jpg'
import GoofyAriana from '../img/WeddingParty/Easter Eggs/arianagoofy.jpg'
import GoofyEm from '../img/WeddingParty/Easter Eggs/emgoofy.jpg'
import GoofyGreg from '../img/WeddingParty/Easter Eggs/greggoofy.jpg'
import GoofyMom from '../img/WeddingParty/Easter Eggs/momgoofy.jpg'
import GoofyThaminda from '../img/WeddingParty/Easter Eggs/thamindagoofy.jpg'
import GoofyJay from '../img/WeddingParty/Easter Eggs/Jay Easter Egg.jpg'
import GoofyMey from '../img/WeddingParty/Easter Eggs/Mom Easter Egg.jpg'
import GoofyPoe from '../img/WeddingParty/Easter Eggs/Poe Poe Easter Egg 5.jpg'
import GoofySakshi from '../img/WeddingParty/Easter Eggs/Sakshi 2.jpg'
import GoofyStaci from '../img/WeddingParty/Easter Eggs/Staci Easter Egg 1.jpg'
import goofyMaya from '../img/WeddingParty/Wedding Party/Maya.jpg'

function WeddingParty() {

    const [goofy, setGoofy] = useState(false);

    const toggleGoofy = () => {
        setGoofy(!goofy)
    }

    const na = () => {
        return
    }

    const useWindowWide = (size) => {
        const [windowWidth, setWindowWidth] = useState(0)
        
        useEffect(() => {
          function handleResize() {
            setWindowWidth(window.innerWidth)
          }
          
          window.addEventListener("resize", handleResize)
          
          handleResize()
          
          return () => { 
            window.removeEventListener("resize", handleResize)
          }
        }, [setWindowWidth])
        
        return windowWidth
    }

    const windowWide = useWindowWide(window.innerWidth)

    return (
        <div className='wedding-party'>
            <h2>Wedding Party</h2>
            <br/>
            <div className="row justify-content-center">
                <div className='wedding-party-parent'>
                    <WeddingPartyParentElement
                        name="Adam Tse"
                        role="Groom"
                        image={Adam}
                        left="-30%"
                        zoom="150%"
                        top="-5%"
                        initgoofy={false}
                        togglegoofy={na}
                        goofy={goofy}
                        goofyimage={GoofyAdam}
                        goofyleft="0%"
                        goofyzoom="100%"
                        goofytop="-30%"
                    />
                </div>
                <div className='wedding-party-parent'>
                    <WeddingPartyParentElement
                        name="Emory Sen"
                        role="Bride"
                        image={Emory}
                        left="-23%"
                        zoom="150%"
                        top="-40%"
                        initgoofy={false}
                        togglegoofy={na}
                        goofy={goofy}
                        goofyimage={GoofyEm}
                        goofyleft="-25%"
                        goofyzoom="140%"
                        goofytop="-25%"
                    />
                </div>
            </div>
            <br/>
            <div className="row justify-content-center">
                <div className='wedding-party-parent'>
                    <WeddingPartyParentElement
                        name="Greg Tse"
                        role="Father of the Groom"
                        description=""
                        image={Greg}
                        left="-20%"
                        zoom="150%"
                        top="-0%"
                        initgoofy={true}
                        toggleGoofy={toggleGoofy}
                        goofy={goofy}
                        goofyimage={GoofyGreg}
                        goofyleft="-25%"
                        goofyzoom="150%"
                        goofytop="-20%"
                    />
                </div>
                <div className='wedding-party-parent'>
                    <WeddingPartyParentElement
                        name="Rose Tse"
                        role="Mother of the Groom"
                        description=""
                        image={Rose}
                        left="-100%"
                        zoom="290%"
                        top="-35%"
                        initgoofy={false}
                        togglegoofy={na}
                        goofy={goofy}
                        goofyimage={GoofyMom}
                        goofyleft="-70%"
                        goofyzoom="250%"
                        goofytop="-10%"
                    />
                </div>
                <div className='wedding-party-parent'>
                    <WeddingPartyParentElement
                        name="Mey Sen"
                        role="Mother of the Bride"
                        description=""
                        image={Mey}
                        left="-70%"
                        zoom="200%"
                        top="-65%"
                        initgoofy={false}
                        togglegoofy={na}
                        goofy={goofy}
                        goofyimage={GoofyMey}
                        goofyleft="-30%"
                        goofyzoom="220%"
                        goofytop="-105%"
                    />
                </div>
            </div>
            <br/>
            <div>
                <div style={{width: "100%"}}>
                    <div className="row justify-content-center">
                        {windowWide >= 577 ? <WeddingPartyElement
                            name="Arif Tse"
                            role="Best Man"
                            description="Arif is Adam’s younger brother. He is Adam’s first gaming buddy and still is to this day. He frequently shows Adam new games and cultural things that Adam becomes too obsessed over after the hype is over."
                            image={Arif}
                            left="-20%"
                            zoom="150%"
                            top="-14%"
                            goofy={goofy}
                            togglegoofy={na}
                            goofyimage={GoofyArif}
                            goofyleft="0%"
                            goofyzoom="100%"
                            goofytop="-45%"
                        /> : <div style={{margin: 'auto'}} className='wedding-party-parent'><WeddingPartyParentElement
                        name="Arif Tse"
                        role="Best Man"
                        description="Arif is Adam’s younger brother. He is Adam’s first gaming buddy and still is to this day. He frequently shows Adam new games and cultural things that Adam becomes too obsessed over after the hype is over."
                        image={Arif}
                        left="-20%"
                        zoom="150%"
                        top="-14%"
                        goofy={goofy}
                        togglegoofy={na}
                        goofyimage={GoofyArif}
                        goofyleft="0%"
                        goofyzoom="100%"
                        goofytop="-45%"/></div>
                    }
                        {windowWide >= 577 ? <WeddingPartyElement
                                name="Sakshi Kaul"
                                role="Maid of Honor"
                                description="Emory and Sakshi first met in 8th grade through a mutual friend. They ended up being in the same honors biology class in 9th grade and became close friends. After graduating, Emory and Sakshi kept in touch and got each other through college with Skype “checkpoints” and hang outs when they were both home during breaks. Sakshi has been there for Emory through the years, and Emory couldn’t imagine having anyone other than her “Sushi” to be her maid of honor. Emory’s favorite memory together is visiting Sakshi in Toledo, OH and going to the Toledo Zoo in 2019."
                                image={Sakshi}
                                left="-5%"
                                zoom="120%"
                                top="-10%"
                                
                                goofy={goofy}
                                togglegoofy={na}
                                goofyimage={GoofySakshi}
                                goofyleft="-20%"
                                goofyzoom="150%"
                                goofytop="-5%"
                            /> : <WeddingPartyParentElement
                            name="Sakshi Kaul"
                            role="Maid of Honor"
                            description="Emory and Sakshi first met in 8th grade through a mutual friend. They ended up being in the same honors biology class in 9th grade and became close friends. After graduating, Emory and Sakshi kept in touch and got each other through college with Skype “checkpoints” and hang outs when they were both home during breaks. Sakshi has been there for Emory through the years, and Emory couldn’t imagine having anyone other than her “Sushi” to be her maid of honor. Emory’s favorite memory together is visiting Sakshi in Toledo, OH and going to the Toledo Zoo in 2019."
                            image={Sakshi}
                            left="-5%"
                            zoom="120%"
                            top="-10%"
                            
                            goofy={goofy}
                            togglegoofy={na}
                            goofyimage={GoofySakshi}
                            goofyleft="-20%"
                            goofyzoom="150%"
                            goofytop="-5%"/>
                        }
                    </div>

                    <div className="row justify-content-center">
                        {windowWide >= 577 ? <WeddingPartyElement
                                name="Ariana Tse"
                                role="Groomsmaid"
                                description="Ariana is Adam’s younger sister. Their relationship is a weird balance between silly banter and deeper life conversations. Adam is so happy to have her by his side for the wedding."
                                image={Ariana}
                                left="-30%"
                                zoom="150%"
                                top="0%"
                                
                                goofy={goofy}
                                togglegoofy={na}
                                goofyimage={GoofyAriana}
                                goofyleft="-10%"
                                goofyzoom="120%"
                                goofytop="-5%"
                            /> : <WeddingPartyParentElement
                                    name="Ariana Tse"
                                    role="Groomsmaid"
                                    description="Ariana is Adam’s younger sister. Their relationship is a weird balance between silly banter and deeper life conversations. Adam is so happy to have her by his side for the wedding."
                                    image={Ariana}
                                    left="-30%"
                                    zoom="150%"
                                    top="0%"
                                    
                                    goofy={goofy}
                                    togglegoofy={na}
                                    goofyimage={GoofyAriana}
                                    goofyleft="-10%"
                                    goofyzoom="120%"
                                    goofytop="-5%"
                                />
                        }
                        {windowWide >= 577 ? <WeddingPartyElement
                                name="Jay Sen"
                                role="Bridesman"
                                description="Jay is Emory’s younger brother. Though they are 14 years apart, Jay is one of Emory’s best friends. Emory can always count on him to make her laugh, and she is so glad to have him by her side on her wedding day."
                                image={Jay}
                                left="-30%"
                                zoom="150%"
                                top="-5%"
                                
                                goofy={goofy}
                                togglegoofy={na}
                                goofyimage={GoofyJay}
                                goofyleft="-20%"
                                goofyzoom="150%"
                                goofytop="-5%"
                            /> : <WeddingPartyParentElement
                            name="Jay Sen"
                            role="Bridesman"
                            description="Jay is Emory’s younger brother. Though they are 14 years apart, Jay is one of Emory’s best friends. Emory can always count on him to make her laugh, and she is so glad to have him by her side on her wedding day."
                            image={Jay}
                            left="-30%"
                            zoom="150%"
                            top="-5%"
                            
                            goofy={goofy}
                            togglegoofy={na}
                            goofyimage={GoofyJay}
                            goofyleft="-20%"
                            goofyzoom="150%"
                            goofytop="-5%"
                        />
                    }
                    </div>

                    <div className="row justify-content-center">
                        {windowWide >= 577 ? <WeddingPartyElement
                            name="Thaminda Edirisooriya"
                            role="Groomsman"
                            description="Thaminda is Adam’s first friend. Their families met through work after Adam’s family moved to Arizona in 1997. Throughout the years they grew together and still are close despite going to different schools and living in different states. Adam’s favorite memory together is going to each other’s house and playing Super Smash Bros. together which became a tradition that transitioned to adulthood."
                            image={Thaminda}
                            left="-10%"
                            zoom="120%"
                            top="-5%"
                            
                            goofy={goofy}
                            togglegoofy={na}
                            goofyimage={GoofyThaminda}
                            goofyleft="-35%"
                            goofyzoom="200%"
                            goofytop="-25%"
                        /> :
                        <WeddingPartyParentElement
                            name="Thaminda Edirisooriya"
                            role="Groomsman"
                            description="Thaminda is Adam’s first friend. Their families met through work after Adam’s family moved to Arizona in 1997. Throughout the years they grew together and still are close despite going to different schools and living in different states. Adam’s favorite memory together is going to each other’s house and playing Super Smash Bros. together which became a tradition that transitioned to adulthood."
                            image={Thaminda}
                            left="-10%"
                            zoom="120%"
                            top="-5%"
                            
                            goofy={goofy}
                            togglegoofy={na}
                            goofyimage={GoofyThaminda}
                            goofyleft="-35%"
                            goofyzoom="200%"
                            goofytop="-25%"
                        />
                        }
                        {windowWide >= 577 ? <WeddingPartyElement
                            name="Poe Poe Kyaw"
                            role="Bridesmaid"
                            description="Emory and Poe Poe also met in 8th grade through mutual friends. They became closer in 9th grade before Poe Poe moved and switched high schools. They remained friends through college and after, and Emory is thankful to have her by her side. Emory’s favorite memory together  is visiting Poe Poe at Penn State University for a weekend in 2016."
                            image={PoePoe}
                            left="-20%"
                            zoom="150%"
                            top="-0%"
                            
                            goofy={goofy}
                            togglegoofy={na}
                            goofyimage={GoofyPoe}
                            goofyleft="-20%"
                            goofyzoom="150%"
                            goofytop="-5%"
                        /> : <WeddingPartyParentElement
                            name="Poe Poe Kyaw"
                            role="Bridesmaid"
                            description="Emory and Poe Poe also met in 8th grade through mutual friends. They became closer in 9th grade before Poe Poe moved and switched high schools. They remained friends through college and after, and Emory is thankful to have her by her side. Emory’s favorite memory together  is visiting Poe Poe at Penn State University for a weekend in 2016."
                            image={PoePoe}
                            left="-20%"
                            zoom="150%"
                            top="-0%"
                            
                            goofy={goofy}
                            togglegoofy={na}
                            goofyimage={GoofyPoe}
                            goofyleft="-20%"
                            goofyzoom="150%"
                            goofytop="-5%"
                        />
                        }
                    </div>

                    <div className="row justify-content-center">
                        {windowWide >= 577 ? <WeddingPartyElement
                            name="Alex Medawar"
                            role="Groomsman"
                            description="Adam met Alex in his sophomore year of undergrad at Arizona State University. They were introduced through a mutual friend where Adam and his roommate were looking for a use for their extra bedroom and Alex was looking for a place to stay by ASU. Adam quickly became close with Alex because of his charismatic personality, fun banter, and shared work out obsessions. Adam’s favorite memory together is midnight multi-hour gym sessions at their apartment with the rest of the habibis and eating gyros and Greek fries afterwards."
                            image={Alex}
                            left="-30%"
                            zoom="150%"
                            top="-30%"
                            togglegoofy={na}
                            
                            goofy={false}
                            goofyleft="-30%"
                            goofyzoom="150%"
                            goofytop="-5%"
                        /> : <WeddingPartyParentElement
                                name="Alex Medawar"
                                role="Groomsman"
                                description="Adam met Alex in his sophomore year of undergrad at Arizona State University. They were introduced through a mutual friend where Adam and his roommate were looking for a use for their extra bedroom and Alex was looking for a place to stay by ASU. Adam quickly became close with Alex because of his charismatic personality, fun banter, and shared work out obsessions. Adam’s favorite memory together is midnight multi-hour gym sessions at their apartment with the rest of the habibis and eating gyros and Greek fries afterwards."
                                image={Alex}
                                left="-30%"
                                zoom="150%"
                                top="-30%"
                                togglegoofy={na}
                                
                                goofy={false}
                                goofyleft="-30%"
                                goofyzoom="150%"
                                goofytop="-5%"
                            />
                        }
                        {windowWide >= 577 ? <WeddingPartyElement
                            name="Staci Desiderio"
                            role="Bridesmaid"
                            description="Emory and Staci met during freshman year of college at Robert Morris University while they were both volunteering at a pumpkin patch. They became close friends and went on many adventures throughout college and after. Emory’s favorite memory together is road tripping to Philadelphia in 2019 to see Dan + Shay."
                            image={Staci}
                            left="-45%"
                            zoom="200%"
                            top="-65%"
                            togglegoofy={na}
                            goofy={goofy}
                            goofyimage={GoofyStaci}
                            goofyleft="-40%"
                            goofyzoom="220%"
                            goofytop="-85%"
                        /> : <WeddingPartyParentElement
                            name="Staci Desiderio"
                            role="Bridesmaid"
                            description="Emory and Staci met during freshman year of college at Robert Morris University while they were both volunteering at a pumpkin patch. They became close friends and went on many adventures throughout college and after. Emory’s favorite memory together is road tripping to Philadelphia in 2019 to see Dan + Shay."
                            image={Staci}
                            left="-45%"
                            zoom="200%"
                            top="-65%"
                            togglegoofy={na}
                            goofy={goofy}
                            goofyimage={GoofyStaci}
                            goofyleft="-40%"
                            goofyzoom="220%"
                            goofytop="-85%"
                        /> 
                        }
                    </div>
                </div>
                
                <div style={{margin: 'auto'}} className='wedding-party-parent'>
                    <WeddingPartyParentElement
                            name="Maya Uyar"
                            role="Flower Girl"
                            description="Emory worked together with Maya’s mom, Anita, at CMU, and they became close friends. Anita brought her family to a work event, and when Emory met Maya, who was 3½ years old at the time, they instantly bonded, and Emory knew right away that she would ask Maya to be her flower girl someday. Ever since, we have had monthly playdates and annual traditions including attending Maya’s ballet recitals and taking a birthday photo with a numbered balloon for the past 5 years. We are thrilled to have Maya as our flower girl and the Persaud-Uyar family as a special part of our wedding day."
                            image={Maya}
                            left="-20%"
                            zoom="150%"
                            top="-45%"
                            togglegoofy={na}
                            goofy={goofy}
                            goofyimage={goofyMaya}
                            goofyleft="-15%"
                            goofyzoom="150%"
                            goofytop="-45%"
                        />
                </div>
            </div>
        </div>
    )
}

export default WeddingParty;