import BannerPlaceholder from '../img/bannerPlaceholder.jpg';

import differenceInDays from 'date-fns/differenceInDays';

function Home() {
    return (
        <div className="home">
            <img style={{width: "70%", "borderRadius": "16px"}} src={BannerPlaceholder}/>
            <div className="row justify-content-center">
                <h3 style={{margin: "10px", width: "fit-content"}}>September 23, 2023</h3>
                <div style={{padding:"0px", width:"2px"}} className="vr"></div>
                <h3 style={{margin: "10px", width: "fit-content"}}>Pittsburgh, PA</h3>
            </div>
            <div style={{paddingTop: "10px"}}>
                <h6>We're getting married!</h6>
                <h6>{differenceInDays(new Date(2023, 8, 23), new Date())} days to go!</h6>
            </div>
            <br/>
            <div>
                <h3>Our Wedding Day</h3>
                <div>Saturday, September 23, 2023</div>
                <div>5:00 p.m.-10:00 p.m.</div>
            </div>
            <br/>
            <div>
                <div>Gardens of Stonebridge</div>
                <div>2 Merriman Road</div>
                <div>Ambridge, PA 15003</div>
            </div>
            <div style={{"margin": "auto", width: "50%"}}>
                <div style={{padding:"0px", width:"2px"}} className="vr"></div>
                <div className="justify-content-center">
                    <div style={{"width": "100%", "fontWeight": "bold"}}>Gates Open</div>
                    <div style={{"width": "100%"}}>4:30 p.m.</div>
                    <div style={{"fontStyle": "italic", "width": "100%"}}>Please plan to arrive and be seated by 4:50 p.m.</div>
                </div>
                <div style={{padding:"0px", width:"2px"}} className="vr"></div>
                <div className="justify-content-center">
                    <div style={{"width": "100%", "fontWeight": "bold"}}>Ceremony</div>
                    <div style={{"width": "100%"}}>5:00 p.m.-5:30 p.m.</div>
                    <div style={{"fontStyle": "italic", "width": "100%"}}>Outdoors if weather permitting & unplugged</div>
                </div>
                <div style={{padding:"0px", width:"2px"}} className="vr"></div>
                <div className=" justify-content-center">
                    <div style={{"width": "100%", "fontWeight": "bold"}}>Reception</div>
                    <div style={{"width": "100%"}}>5:30 p.m.-10:00 p.m.</div>
                    <div style={{"fontStyle": "italic", "width": "100%"}}>Our wedding will be alcohol-free. We are crafting special mocktails for your enjoyment :).
</div>
                </div>
            </div>
            
        </div>
    )
}

export default Home;