import { useState } from 'react';

import FullNameDetail from './FullNameDetail';
import RSVPDetail from './RSVPDetail';
import PlusOneDetail from './PlusOneDetail';
import HotelDetail from './HotelDetail';
import TransportationDetail from './TransportationDetail';
import MusicDetail from './MusicDetail';
import RSVPConfirmation from './RSVPConfirmation';
import RSVPComplete from './RSVPComplete';

function RSVP() {
    const [step, setStep] = useState(1); 
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [fullName, setFullName] = useState("");
    const [indexGuest, setIndexGuest] = useState(-1);
    const [guestList, setGuestList] = useState([]);
    const [knownPlusOnes, setKnownPlusOnes] = useState([]);
    const [toAddPlusOnes, setToAddPlusOnes] = useState(0);
    const [toAddPlusOneNames, setToAddPlusOneNames] = useState([]);
    const [error, setError] = useState(false);

    const [rsvpsToChange, setRsvpsToChange] = useState([]);
    const [newGuestList, setNewGuestList] = useState([]);

    const RSVPSteps = {
        "FullNameDetail": 1,
        "RSVPDetail": 2,
        "PlusOneDetail": 3,
        "HotelDetail": 4,
        "TransportationDetail": 5,
        "MusicDetail": 6,
        "RSVPConfirmation": 7,
        "RSVPComplete": 8
    }

    const findGuest = (inputName, guestListArg) => {
        let found = false;
        let i = 0;
        for (i=0; i<guestListArg.length; i++) {
            if (guestListArg[i]["guestName"] !== undefined) {
                if (guestListArg[i]["guestName"].toLowerCase().toLowerCase() === inputName.toLowerCase()) {
                    found = true;
                    return i
                }
            }
        }

        return -1
    }

    const checkGuestList = (guestListArg = guestList) => {
        let i = findGuest(fullName, guestListArg);

        // Invited Guest
        if (i !== -1) {
            console.log("VALIDATED");
            // Save index of RSVPing guest
            setIndexGuest(i);
            // Save number of unknown plus ones
            setToAddPlusOnes(parseInt(guestListArg[i]['plusOne']));
            let numberGuests = 1;
            var guestNames = []
            // Save affiliates to RSVP for
            if (guestListArg[i]['affiliates'] !== undefined && guestListArg[i]['affiliates'] !== "") {
                var affiliatesArray = guestListArg[i]['affiliates'].split(",");
                guestNames = guestNames.concat(affiliatesArray);
                setKnownPlusOnes(affiliatesArray);
                numberGuests += affiliatesArray.length;
            }
            let newRsvpsToChange = []
            newRsvpsToChange.push({
				"formatName": guestListArg[i]["formatName"],
                "guestName": guestListArg[i]["guestName"],
                "rsvp":  guestListArg[i]["rsvp"], 
                "dietary": guestListArg[i]["dietary"],
                "music": guestListArg[i]["music"],
                "hotel": guestListArg[i]["hotel"],
                "transportation": guestListArg[i]["transportation"]
            })
            for (let j=0; j<guestNames.length; j++) {
                let guestIndex = findGuest(guestNames[j], guestListArg)
                newRsvpsToChange.push({
					"formatName": guestListArg[guestIndex]["formatName"],
                    "guestName": guestNames[j],
                    "rsvp":  guestListArg[guestIndex]["rsvp"], 
                    "dietary": guestListArg[guestIndex]["dietary"],
                    "music": guestListArg[guestIndex]["music"],
                    "hotel": guestListArg[guestIndex]["hotel"],
                    "transportation": guestListArg[guestIndex]["transportation"]
                })
            }

            setRsvpsToChange(newRsvpsToChange);
            setError(false);
            setStep(step+1)
        }
        // Uninvited
        else {
            setError(true);
            console.log("Your name didn't appear in the guest list");
        }
    }

    const validateName = (event) => {
        console.log("ATTEMPTING FETCH")
        fetch(`https://api.sheety.co/1cffe7f8ea500f6f4a0fb90e2d0f6c7d/weddingRsvpForm/sheet1`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => response.json())
        .then(json => {
            console.log(fullName);
            console.log(json.sheet1);
            setGuestList(json.sheet1);
            checkGuestList(json.sheet1);
        });
    }

    const checkAffiliateRsvp = () => {
        for (let i=0; i<rsvpsToChange.length; i++) {
            if (rsvpsToChange[i]['rsvp'] === "") {
                return false;
            }
        }
        return true;
    }

    const checkPlusOneName = () => {
        for (let i=0; i<toAddPlusOneNames.length; i++) {
            if (toAddPlusOneNames[i]['guestName'] === "") {
                return false;
            }
        }
        return true;
    }

    const checkHotel = () => {
        console.log(rsvpsToChange[0])
        if (rsvpsToChange[0]['hotel'] === "" || rsvpsToChange[0]['hotel'] === undefined) {
            return false;
        }
        return true;
    }

    const checkTransportation = () => {
        if (parseInt(rsvpsToChange[0]['transportation']) >= 0 && parseInt(rsvpsToChange[0]['transportation']) < 5) {
            return true;
        }
        else {
            return false;
        }
    }

    const checkAllNo = () => {
        var allNo = true
        for (var i = 0; i<rsvpsToChange.length; i++) {
            if (rsvpsToChange[i]['rsvp'] == 'yes') {
                allNo = false
            }
        }

        return allNo
    }

    const checkUnknownPlusOne = (event) => {
        if (checkAffiliateRsvp()) {
            setError(false);
            if (checkAllNo()) {
                setStep(RSVPSteps['RSVPConfirmation'])
            }
            else if (toAddPlusOnes === 0) {
                setStep(RSVPSteps['HotelDetail']);
            }
            else {
                setStep(RSVPSteps['PlusOneDetail']);
            }
        }
        else {
            setError(true);
        }
    }

    const addPlusOne = (event) => {
        let newToAddPlusOneNames = [ ...toAddPlusOneNames ]
        newToAddPlusOneNames.push({"formatName": "", "guestName": "", "rsvp":  "yes", "dietary": "", "music": "", "hotel": "", "transportation": ""})
        setToAddPlusOneNames(newToAddPlusOneNames)
    }

    const removePlusOne = index => (event) => {
        let newToAddPlusOneNames = [ ...toAddPlusOneNames ]
        newToAddPlusOneNames.splice(index, 1)
        setToAddPlusOneNames(newToAddPlusOneNames)
    }

    const submitHotel = (event) => {
        if (checkHotel()) {
            setError(false);
            if (rsvpsToChange[0]['hotel'] === "yes") {
                incStep();
            }
            else {
                setStep(RSVPSteps['MusicDetail']);
            }
        } else {
            setError(true);
        }
    }

    const submitTransportation = (event) => {
        if (checkTransportation()) {
            setError(false);
            incStep();
        } else {
            setError(true);
        }
    }

    const editRow = (index, row) => {
        let url = 'https://api.sheety.co/1cffe7f8ea500f6f4a0fb90e2d0f6c7d/weddingRsvpForm/sheet1/' + index;
        let body = {
            sheet1: row
        }
        fetch(url, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then(json => {
            // Do something with object
            console.log(json.sheet1);
        });
    }

    const addRow = (row) => {
        let url = 'https://api.sheety.co/1cffe7f8ea500f6f4a0fb90e2d0f6c7d/weddingRsvpForm/sheet1';
        let body = {
            sheet1: row
        }
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then(json => {
            // Do something with object
            console.log(json.sheet1);
        });
    }

    const submitRsvp = (event) => {
        // Create affiliates array
        var i = 0;
        let names = []
        for (i = 0; i<rsvpsToChange.length; i++) {
            names.push(rsvpsToChange[i]["guestName"])
        }
        for (i = 0; i<toAddPlusOneNames.length; i++) {
            names.push(toAddPlusOneNames[i]["guestName"])
        }
        console.log(names)
        
        // Loop through all rsvpsToAdd
        for (i = 0; i<toAddPlusOneNames.length; i++) {
            let row = toAddPlusOneNames[i]
            let currentGuestIndex = names.indexOf(row["guestName"])
            
            let namesAffiliatesArray = names.slice(0);
            namesAffiliatesArray.splice(currentGuestIndex, 1)
            let affiliatesStr = namesAffiliatesArray.toString()

            row["plusOne"] = toAddPlusOnes - toAddPlusOneNames.length
            row["affiliates"] = affiliatesStr

            console.log(row)
            addRow(row);
        }

        // Loop through all rsvpsToChange
        for (i = 0; i<rsvpsToChange.length; i++) {
            let row = rsvpsToChange[i]
            let currentGuestIndex = names.indexOf(row["guestName"])

            let namesAffiliatesArray = names.slice(0);
            namesAffiliatesArray.splice(currentGuestIndex, 1)
            let affiliatesStr = namesAffiliatesArray.toString()

            row["plusOne"] = toAddPlusOnes - toAddPlusOneNames.length
            row["affiliates"] = affiliatesStr

            let index = findGuest(row["guestName"], guestList) + 2

            editRow(index, row)
        }

        incStep();
    }

    const resetStepOne = () => {
        setKnownPlusOnes([]);
        setToAddPlusOnes(0);
        prevStep();
    }

    const prevHotel = () => {
        setError(false);
        if (toAddPlusOnes === 0) {
            setStep(RSVPSteps['RSVPDetail'])
        }
        else {
            decStep()
        }
    }

    const prevMusic = () => {
        setError(false);
        if (rsvpsToChange[parseInt(indexGuest)]["hotel"] === "no") {
            setStep(RSVPSteps['HotelDetail'])
        }
        else {
            decStep()
        }
    }

    const prevStep = () => {
        setError(false);
        if (step === RSVPSteps['RSVPConfirmation'] && checkAllNo()) {
            setStep(RSVPSteps['RSVPDetail'])
        }
        else if (step === RSVPSteps['RSVPConfirmation'] && toAddPlusOnes === 0) {
            setStep(RSVPSteps['MusicDetail'])
        }
        else if (step === 2) {
            setToAddPlusOnes(0)
            setToAddPlusOneNames([])
            decStep()
        }
        else {
            decStep()
        }
    }

    const plusOneNext = () => {
        if (checkPlusOneName()) {
            setError(false);
            incStep();
        } else {
            setError(true);
        }
    }

    const incStep = () => {
        setStep(step + 1);
    }

    const decStep = () => {
        setStep(step - 1)
    }

    const reset = () => {
        setStep(RSVPSteps['FullNameDetail'])
        setToAddPlusOneNames([])
        setToAddPlusOnes(0)
        setRsvpsToChange([])
        setFullName("")
    }

    const handleChange = input => e => {
        if (input === "fullName") {
            setFullName(e.target.value.toLowerCase());
        }
        if (input === "firstName") {
            setFirstName(e.target.value)
            setFullName(e.target.value + " " + lastName)
        }
        if (input === "lastName") {
            setLastName(e.target.value)
            setFullName(firstName + " " + e.target.value)
        }
    }

    const handleListChange = (input, index) => e => {
        if (input === "dietary") {
            let newRsvpsToChange = [ ...rsvpsToChange ];
            let rsvpToChange = newRsvpsToChange[index];
            rsvpToChange["dietary"] = e.target.value;
            setRsvpsToChange(newRsvpsToChange);
         }
         else if (input === "rsvp") {
            let newRsvpsToChange = [ ...rsvpsToChange ];
            let rsvpToChange = newRsvpsToChange[index];
            rsvpToChange["rsvp"] = e.target.value;
            setRsvpsToChange(newRsvpsToChange);
         }
         else if (input === "plusOneName") {
            let newToAddPlusOneNames = [ ...toAddPlusOneNames ];
            let toAddPlussOneNameToChange = newToAddPlusOneNames[index];
            toAddPlussOneNameToChange["guestName"] = e.target.value.toLowerCase();
			toAddPlussOneNameToChange["formatName"] = e.target.value;
            setToAddPlusOneNames(newToAddPlusOneNames);
         }
         else if (input === "plusOneRsvp") {
            let newToAddPlusOneNames = [ ...toAddPlusOneNames ];
            let toAddPlussOneNameToChange = newToAddPlusOneNames[index];
            toAddPlussOneNameToChange["rsvp"] = e.target.value;
            setToAddPlusOneNames(newToAddPlusOneNames);
         }
         else if (input === "plusOneDietary") {
            let newToAddPlusOneNames = [ ...toAddPlusOneNames ];
            let toAddPlussOneNameToChange = newToAddPlusOneNames[index];
            toAddPlussOneNameToChange["dietary"] = e.target.value;
            setToAddPlusOneNames(newToAddPlusOneNames);
         }
         else if (input === "hotel") {
            console.log(rsvpsToChange)
            let newRsvpsToChange = [ ...rsvpsToChange ];
            let rsvpToChange = newRsvpsToChange[index];
            rsvpToChange["hotel"] = e.target.value;
            setRsvpsToChange(newRsvpsToChange);
         }
         else if (input === "transportation") {
            let newRsvpsToChange = [ ...rsvpsToChange ];
            let rsvpToChange = newRsvpsToChange[index];
            rsvpToChange["transportation"] = e.target.value;
            setRsvpsToChange(newRsvpsToChange);
         }
         else if (input === "music") {
            let newRsvpsToChange = [ ...rsvpsToChange ];
            let rsvpToChange = newRsvpsToChange[index];
            rsvpToChange["music"] = e.target.value;
            setRsvpsToChange(newRsvpsToChange);
         }
    }

    return (
        <div>
            <h2>RSVP</h2>
            { step === RSVPSteps['FullNameDetail'] ?
                <FullNameDetail
                    nextStep={ validateName }
                    handleChange={ handleChange }
                    fullName={ fullName }
                    firstName={ firstName }
                    lastName={ lastName }
                    error={ error }
                />
                : ""
            }
            { step === RSVPSteps['RSVPDetail'] ?
                <RSVPDetail
                    prevStep={ resetStepOne }
                    nextStep={ checkUnknownPlusOne }
                    handleChange={ handleListChange }
                    fullName={ fullName }
                    knownPlusOne={ knownPlusOnes }
                    rsvpsToChange={ rsvpsToChange }
                    error={ error }
                />
                : ""
            }
            { step === RSVPSteps['PlusOneDetail'] ?
                <PlusOneDetail
                    prevStep={ prevStep }
                    nextStep={ plusOneNext }
                    handleChange={ handleListChange }
                    addPlusOne={ addPlusOne }
                    removePlusOne={ removePlusOne }
                    toAddPlusOnes={ toAddPlusOnes }
                    rsvpsToAdd={ toAddPlusOneNames }
                    error={ error }
                />
                : ""
            }
            { step === RSVPSteps['HotelDetail'] ?
                <HotelDetail
                    prevStep={ prevHotel }
                    nextStep={ submitHotel }
                    handleChange={ handleListChange }
                    index={ 0 }
                    rsvpsToChange={ rsvpsToChange }
                    error = { error }
                />
                : ""
            }
            { step === RSVPSteps['TransportationDetail'] ?
                <TransportationDetail
                    prevStep={ decStep }
                    nextStep={ submitTransportation }
                    handleChange={ handleListChange }
                    index={ 0 }
                    rsvpsToChange={ rsvpsToChange }
                    numberParty={ rsvpsToChange.length + toAddPlusOneNames.length }
                    error = { error }
                />
                : ""
            }
            { step === RSVPSteps['MusicDetail'] ?
                <MusicDetail
                    prevStep={ prevMusic }
                    nextStep={ incStep }
                    handleChange={ handleListChange }
                    index={ 0 }
                    rsvpsToChange={ rsvpsToChange }
                    error = { error }
                />
                : ""
            }
            { step === RSVPSteps['RSVPConfirmation'] ?
                <RSVPConfirmation
                    prevStep={ prevStep }
                    nextStep={ submitRsvp }
                    rsvpsToChange={ rsvpsToChange }
                    rsvpsToAdd={ toAddPlusOneNames }
                />
                : ""
            }
            { step === RSVPSteps['RSVPComplete'] ?
                <RSVPComplete
                    reset={ reset }
                />
                : ""
            }
        </div>
    )
}

export default RSVP;
