import Soergel1 from '../img/ThingsToDo/Things To Do/Soergel_s 1.jpg'
import Soergel2 from '../img/ThingsToDo/Things To Do/Soergel\'s1.png'
import Baron1 from '../img/ThingsToDo/Things To Do/Baron 1.jpg'
import Baron2 from '../img/ThingsToDo/Things To Do/Baron 2.jpg'
import Baron3 from '../img/ThingsToDo/Things To Do/Baron 3.jpg'
import ZooLanterns1 from '../img/ThingsToDo/Things To Do/Zoo.jpg'
import ZooLanterns2 from '../img/ThingsToDo/Things To Do/Zoo2.jpg'
import ZooLanterns3 from '../img/ThingsToDo/Things To Do/Zoo Lanterns.jpg'
import ZooEllie1 from '../img/ThingsToDo/Things To Do/Zoo Ellie 1.jpg'
import ZooEllie2 from '../img/ThingsToDo/Things To Do/Zoo Ellie 2.jpg'
import MountWashington1 from '../img/ThingsToDo/Things To Do/Pittsburgh Mount Washington 1.JPG'
import MountWashington2 from '../img/ThingsToDo/Things To Do/Mount Washington 2.jpg'
import Point from '../img/ThingsToDo/Things To Do/Pittsburgh Point.jpg'
import Phipps from '../img/ThingsToDo/Things To Do/Phipps.png'
import StationSquare from '../img/ThingsToDo/Things To Do/Pittsburgh Station Square.jpg'
import StationSquare2 from '../img/ThingsToDo/Things To Do/Pittsburgh Boat 1.jpg'
import ThingsToDoElement from './ThingsToDoElement'

import pittsburghsign from '../img/Memories/pittsburghsign.jpg'

function ThingsToDo() {
    return (
        <div className='things-to-do'>
            <h2>Things to Do</h2>
            <div>Pittsburgh is a beautiful city with lots of things to do, especially in the fall!</div>
            <div>Our Favorite Places:</div>
            <br/>
            <ThingsToDoElement
                name="Soergel Orchards"
                address="2573 Brandt School Road"
                address2="Wexford, PA 15090"
                description="As you may have read in our story, Soergel’s is a very special place to us. It is only about half a mile from the preferred hotel, and we recommend checking it out if you get the chance! They have an awesome Fall Festival with apple picking, a pumpkin patch, flower fields, amazing apple cider, and so much more!"
                image={Soergel2}
                secondImage={Soergel1}
                link="https://www.soergels.com/"
            />
            <img className='random-pic' src={pittsburghsign}></img>
            <div>Our venue is about a 30-minute drive from downtown Pittsburgh. There are many fun things to do closer to the city. Here are a few of our favorites:</div>
            <br/>
            <ThingsToDoElement
                name="Baron Batch’s The Residency"
                address="2708 Sidney Street"
                address2="Pittsburgh, PA 15203"
                description="Emory first discovered Baron Batch when she attended one of her friend’s concerts in college, and Baron was live painting in the background. She became a fan of his artwork, and took Adam on a date to Baron’s former gallery in Homestead, PA. We have collected some of his art from his gallery for our home and are always on the lookout for his free art drops around the city. We came close to getting one, but even though we were there within a few minutes, we weren’t fast enough. You can follow him on Instagram @baronbatch for drop locations, and you might get lucky :). Either way, we recommend checking out his new gallery in Pittsburgh’s South Side if you have time!"
                image={Baron1}
                secondImage={Baron3}
                link="https://baronbatch.com/"
            />
            <ThingsToDoElement
                name="Phipps Conservatory and Botanical Gardens"
                address="1 Schenley Drive"
                address2="Pittsburgh, PA 15213"
                description="Another one of our favorite spots is Phipps! We love walking through the amazing indoor oasis and the outdoor Japanese garden. There are rotating seasonal exhibits, so there is always something new to see. Most recently, we attended the orchid show in February 2023, and we look forward to the fall shows! We highly recommend checking it out if you get the chance!"
                image={Phipps}
                secondImage=""
                link="https://www.phipps.conservatory.org/"
            />
            <ThingsToDoElement
                name="Pittsburgh Zoo & Aquarium"
                address="7370 Baker Street"
                address2="Pittsburgh, PA 15206"
                description="We used to live in an apartment right next to the zoo and visited often when we lived in the area. Some of our favorite memories are seeing the elephants up close and watching the penguin parade during the winter. During the fall, there is an Asian Lantern Festival, which we highly recommend if you have a free evening when you are in town!"
                image={ZooEllie1}
                secondImage={ZooLanterns3}
                link="https://www.pittsburghzoo.org/"
            />
            <ThingsToDoElement
                name="Mount Washington Overlook"
                address=""
                description="Mount Washington is the spot to visit for panoramic views of Pittsburgh’s iconic skyline with over 400 bridges along its 3 rivers. If you want the full Pittsburgh experience, we recommend riding the Duquesne Incline up to the Mount Washington Grandview Overlook. Mount Washington is also home to some of Pittsburgh’s best upscale restaurants—Altius and Monterey Bay Fish Grotto are our favorites."
                image={MountWashington1}
                secondImage={MountWashington2}
                link="https://www.visitpittsburgh.com/neighborhoods/mt-washington/"
            />
            <ThingsToDoElement
                name="Point State Park"
                address=""
                description="Point State Park is the triangular park where Pittsburgh’s three rivers meet. It is a great area to walk around or just hang out by the fountain."
                image={Point}
                secondImage=""
                link="https://pittsburghpa.gov/citiparks/point-state"
            />
            <ThingsToDoElement
                name="Station Square"
                address=""
                description="Station Square is located on the South Shore of Pittsburgh and offers many dining and shopping spots and the opportunity to see the city from a different angle. If you are interested in seeing more of the city, we recommend the Gateway Clipper Fleet river cruises, which dock at Station Square."
                image={StationSquare}
                secondImage={StationSquare2}
                link="https://www.gatewayclipper.com/"
            />
            <div>
                There are many other things to do in Pittsburgh including museums, sports events, dining, and outdoor activities:
            </div>
            <a href="https://www.visitpittsburgh.com/things-to-do/">https://www.visitpittsburgh.com/things-to-do/</a>
        </div>
    )
}

export default ThingsToDo;