function RSVPConfirmation(props) {
    return (
        <div style={{"margin": "auto", "width":"80%"}}>
            <h3>RSVP Confirmation</h3>
            <br/>
            <table style={{tableLayout: "fixed", width: "100%"}}>
                {props.rsvpsToChange.map(function(object, index) {
                    return <tr style={{"borderBottom": "1px solid grey"}}>
                                <td style={{"width": "20%", "padding": "15px"}}>{ props.rsvpsToChange[parseInt(index)]["formatName"]}</td>
                                <td style={{"width": "20%", "padding": "15px"}}>RSVP: {props.rsvpsToChange[parseInt(index)]["rsvp"] === "yes" ? "Yes": "No"}</td>
                                <td style={{"width": "60%", "padding": "15px"}}>{ props.rsvpsToChange[parseInt(index)]["dietary"]}</td>
                        </tr>
                })}
                {props.rsvpsToAdd.map(function(object, index) {
                    return <tr style={{"borderBottom": "1px solid grey"}}>
                        <td style={{"width": "20%", "padding": "15px"}}>{ props.rsvpsToAdd[parseInt(index)]["guestName"]}</td>
                        <td style={{"width": "20%", "padding": "15px"}}>RSVP: {props.rsvpsToAdd[parseInt(index)]["rsvp"] === "yes" ? "Yes": "No"}</td>
                        <td style={{"width": "40%", "padding": "15px"}}>{ props.rsvpsToAdd[parseInt(index)]["dietary"]}</td>
                    </tr>
                })}
            </table>
            <br/>
            <button onClick={ props.prevStep }>Prev</button>
            <button onClick={ props.nextStep }>Next</button>
        </div>
    )
}

export default RSVPConfirmation;