function TransporationDetail(props) {
    return (
        <div>
            <div style={{"width": "100%"}}>
                We will be providing transportation from the Hampton Inn to the venue and back.
            </div>
            <br/>
            <div style={{"width": "100%"}}>
                How many people in your party do you expect will take the shuttle?
            </div>
            <input
                type='number'
                style={{"width": "fit-content", "margin": "auto"}}
                onChange={props.handleChange("transportation", parseInt(props.index))}
                value= {props.rsvpsToChange[parseInt(props.index)]["transportation"]}
                max= {props.numberParty}
                min= {0}
            />
            <br/>
            { props.error ? 
                <div>Please enter a number for the amount of guests in your party that will taking the shuttle</div>
                : ""
            }
            <br/>
            <button onClick={ props.prevStep }>Prev</button>
            <button onClick={ props.nextStep }>Next</button>
        </div>
    )
}

export default TransporationDetail;