function Travel() {
    return (
        <div className="travel">
            <h2>Travel</h2>
            <div style={{padding: "10px", margin: "auto"}}>
                <h3>Pittsburgh International Airport</h3>
                <br/>
                <div style={{textAlign: "justify"}}>The closest airport to the venue is the Pittsburgh International Airport. It is about 30 minutes away by car from the preferred hotel.</div>
                <br/>
                <div>1000 Airport Blvd.<br/>Pittsburgh, PA 15231</div>
                <div>(412) 472-3525</div>
                <br/>
                <button>
                    <a style={{color: "inherit"}} href='https://flypittsburgh.com/'>Website</a>
                </button>
                <br/>
            </div>
            <br/>
            <hr style={{padding:"0px", height:"2px"}}></hr>
            <br/>
            <h2>Hotel</h2>
            <div style={{padding: "10px", margin: "auto"}}>
                <h3>Hampton Inn Pittsburgh/Wexford-Sewickley</h3>
                <br/>
                <div>2622 Wexford Bayne Road<br/>Wexford, PA 15143</div>
                <div>(412)-528-1901</div>
                <br/>
                <div>Check-In: Friday, September 22, 2023/Check-Out: Sunday, September 24, 2023</div>
                <br/>
                <div style={{textAlign: "justify"}}>We have a hotel block at the Hampton Inn Pittsburgh/Wexford-Sewickley, located at 2622 Wexford Bayne Rd, Wexford, PA 15143 (about 15 minutes away by car from our venue), from Friday, September 22, 2023 to Sunday, September 24, 2023.</div>
                <br/>
                <div style={{textAlign: "center"}}>
                    The group rate is $109 per night for 1 King and $129 for 2 Queens.
                </div>
                <br/>
                <div style={{textAlign: "center"}}>
                    You can book within our block by entering “TSW” for the group code at the link below:
                </div>
                <a href="https://www.hilton.com/en/hotels/pitwshx-hampton-pittsburgh-wexford-sewickley/" style={{textAlign: "center"}}>
                    https://www.hilton.com/en/hotels/pitwshx-hampton-pittsburgh-wexford-sewickley/
                </a>
                <br/>
                <br/>
                <div style={{textAlign: "center"}}>You can also call the hotel at (412)-528-1901 and mention the “Sen-Tse Wedding” to book within the block.</div>
                <br/>
                <div style={{textAlign: "center"}}>We have a certain number of each type of room in our block. If the room type you need is not available, please let us know at adamandemory@gmail.com or (412)-877-8881, and we will extend our block.</div>
                <br/>
                <div style={{textAlign: "center"}}>Please note that the cut-off date to reserve a room in the block is Sunday, August 20, 2023. After this date, rooms will be at the regular rate, and availability is not guaranteed. If you plan to stay at this hotel, we recommend booking your room as soon as you can.</div>
                <br/>
                <button>
                    <a style={{color: "inherit"}} href='https://www.hilton.com/en/hotels/pitwshx-hampton-pittsburgh-wexford-sewickley/'>Website</a>
                </button>
                <br/>
            </div>
            <br/>
        </div>
    )
}

export default Travel;
