import{ useState, useEffect } from 'react';

function ThingsToDoElement(props) {
    var content;

    const useWindowWide = (size) => {
        const [windowWidth, setWindowWidth] = useState(0)
        
        useEffect(() => {
          function handleResize() {
            setWindowWidth(window.innerWidth)
          }
          
          window.addEventListener("resize", handleResize)
          
          handleResize()
          
          return () => { 
            window.removeEventListener("resize", handleResize)
          }
        }, [setWindowWidth])
        
        return windowWidth
    }

    const windowWide = useWindowWide(window.innerWidth)

    var firstImageContainerStyle = {width: "30%", margin: "auto"}
    var textContainerStyle = {width: "70%", margin: "auto"}
    var baseFirstImage = (
            <div style={firstImageContainerStyle}>
                <img style={{width: "100%", height:"auto", objectFit:"contain", borderRadius:"16px", margin:"auto"}} src={props.image}/>
            </div>
    )
    var baseText = (
        <div style={textContainerStyle}>
            <h3>{props.name}</h3>
            <div style={{padding: "30px", textAlign: "justify"}}>{props.description}</div>
            <div>{props.address}</div>
            <div>{props.address2}</div>
            <br/>
            <button>
                <a href={props.link} style={{color: "inherit"}}>Website</a>
            </button>
            <br/>
        </div>
    )
    if (props.secondImage === "") {
        if (windowWide < 577) {
            firstImageContainerStyle.width = "100%"
            textContainerStyle.width = "90%"
            content = (
                <div style={{margin: "auto"}}>

                        {baseFirstImage}

                    {baseText}
                </div>
            )
        }
        else {
            content = (
                <div style={{margin: "auto"}} className="row justify-content-center">
                    {baseFirstImage}
                    {baseText}
                </div>
            )
        }
    } else {
        firstImageContainerStyle.width = "23%"
        textContainerStyle.width = "54%"
        if (windowWide < 577) {
            firstImageContainerStyle.width = "50%"
            textContainerStyle.width = "90%"
            content = (
                <div style={{margin: "auto"}}>
                    <div className="row justify-content-center">
                        {baseFirstImage}
                        <div style={{width: "50%", margin: "auto", paddingBottom: "20px"}}>
                            <img style={{width: "100%", height:"auto", objectFit:"contain", borderRadius:"16px", margin:"auto"}} src={props.secondImage}/>
                        </div>
                    </div>
                    {baseText}
                </div>
            )
        }
        else {
            content = (
                <div style={{margin: "auto"}} className="row justify-content-center">
                    {baseFirstImage}
                    {baseText}
                    <div style={{width: "23%", margin: "auto"}}>
                        <img style={{width: "100%", height:"auto", objectFit:"contain", borderRadius:"16px", margin:"auto"}} src={props.secondImage}/>
                    </div>
                </div>
            )
        }
    }

    return(
        <div>
            <div>
                {content}
            </div>
            <br/>
            <hr style={{padding:"0px", height:"2px"}}></hr>
            <br/>
        </div>
    )
}

export default ThingsToDoElement;