import{ useState, useEffect } from 'react';

function TimelineElement(props) {
    const index = props.index
    console.log(index % 2)

    const useWindowWide = (size) => {
        const [windowWidth, setWindowWidth] = useState(0)
        
        useEffect(() => {
          function handleResize() {
            setWindowWidth(window.innerWidth)
          }
          
          window.addEventListener("resize", handleResize)
          
          handleResize()
          
          return () => { 
            window.removeEventListener("resize", handleResize)
          }
        }, [setWindowWidth])
        
        return windowWidth
    }

    const windowWide = useWindowWide(window.innerWidth)

    const images = props.image
    var width = "22%"
    if (props.fit) {
        width = "100%"
    }

    var nojustify = "justify"
    var description = (
        <div lang="en" style={{"width": "100%", "transform": "scale(1, 1) translate(0px, 0px)"}}>
            <div style={{"textAlign": nojustify, "wordSpacing":"-1px", "textJustify": "distribute","textAlignLast": "left", "hyphens": "auto"}}>{props.description}</div>
        </div>
    )
    
    if (props.nojustify) {
        nojustify = "center"
        description = (
            <div lang="en" style={{"width": "100%", "transform": "scale(1, 1) translate(0px, 0px)"}}>
                <div style={{"textAlign": nojustify}}>{props.description}</div>
            </div>
        )
    }
    console.log(images[0])

    var timelineElementStyle = {}
    if (windowWide > 767) {
        timelineElementStyle ={"width": width}
    }

    return (
        <div style={timelineElementStyle} className="justify-content-center timeline-element">
            { index % 2 === 0 ? 
                <div>
                    {description}
                    <br/>
                    {images.map(function(object, i) {
                        return (
                            <div>
                                <img style={{"height": "auto", "width": "100%", "borderRadius": "16px", "marginBottom": "20px"}} src={object}/>
                            </div>
                        )
                    })}
                </div>
                :
                <div>
                    {images.map(function(object, i) {
                        return (
                            <div>
                                <img style={{"height": "auto", "width": "100%", "borderRadius": "16px", "marginBottom": "20px"}} src={object}/>
                            </div>
                        )
                    })}
                    {description}
                    <br/>
                </div>
            }
        </div>
    )
}

export default TimelineElement;