import photo1 from '../img/default/photos1.jpg'
import photo2 from '../img/default/photos2.jpg'
import photo3 from '../img/default/photos3.jpg'
import photo4 from '../img/default/photos4.jpg'
import photo5 from '../img/default/photos5.jpg'
import photo6 from '../img/default/photos6.jpg'
import photo7 from '../img/default/photos7.jpg'
import photo8 from '../img/default/photos8.jpg'
import photo9 from '../img/default/photos9.jpg'
import photo10 from '../img/default/photos10.jpg'
import photo11 from '../img/default/photos11.jpg'
import photo12 from '../img/default/photos12.jpg'

import photo1thumb from '../img/thumbnail/photos1.jpg'
import photo2thumb from '../img/thumbnail/photos2.jpg'
import photo3thumb from '../img/thumbnail/photos3.jpg'
import photo4thumb from '../img/thumbnail/photos4.jpg'
import photo5thumb from '../img/thumbnail/photos5.jpg'
import photo6thumb from '../img/thumbnail/photos6.jpg'
import photo7thumb from '../img/thumbnail/photos7.jpg'
import photo8thumb from '../img/thumbnail/photos8.jpg'
import photo9thumb from '../img/thumbnail/photos9.jpg'
import photo10thumb from '../img/thumbnail/photos10.jpg'
import photo11thumb from '../img/thumbnail/photos11.jpg'
import photo12thumb from '../img/thumbnail/photos12.jpg'

import PhotoGallery from './PhotoGallery'

function Photos() {
    let photoGalleryImages = [
        {"source": photo1, "thumb": photo1thumb, "width": 6512, "height": 4341},
        {"source": photo2, "thumb": photo2thumb,"width": 3521, "height": 5281},
        {"source": photo3, "thumb": photo3thumb,"width": 4840, "height": 3227},
        {"source": photo4, "thumb": photo4thumb,"width": 6526, "height": 4351},
        {"source": photo5, "thumb": photo5thumb,"width": 2395, "height": 3593},
        {"source": photo6, "thumb": photo6thumb,"width": 6459, "height": 4306},
        {"source": photo7, "thumb": photo7thumb,"width": 6720, "height": 4480},
        {"source": photo8, "thumb": photo8thumb,"width": 4065, "height": 6097},
        {"source": photo9, "thumb": photo9thumb,"width": 5803, "height": 3869},
        {"source": photo10, "thumb": photo10thumb,"width": 5870, "height": 3913},
        {"source": photo11, "thumb": photo11thumb,"width": 6720, "height": 4480},
        {"source": photo12, "thumb": photo12thumb,"width": 6720, "height": 4480},
    ]

    return (
        <div>
            <h2>Our Engagement Photos</h2>
            <PhotoGallery images={photoGalleryImages}/>
            <div>Engagement photos by Catherine Acevedo</div>
        </div>
    )
}

export default Photos;