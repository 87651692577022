import QuestionsElement from "./QuestionsElement";

function Questions() {
    return (
        <div className="q-a">
            <h2>Q & A</h2>
            <QuestionsElement
                question="Is there parking at the venue?"
                answer="There is plenty of free parking in the lot at the venue if you will be providing your own transportation."
                justify={true}
            />
            <QuestionsElement
                question="Is there a shuttle?"
                answer="If you are staying at the Hampton Inn Pittsburgh/Wexford-Sewickley, we will provide a shuttle to and from the venue. We will provide the shuttle schedule closer to the date."
                justify={true}
            />
            <QuestionsElement
                question="What is the dress code?"
                answer="The dress code is semi-formal attire (pastels/lighter shades of colors are encouraged!). Please note that our ceremony will be outdoors, weather permitting, and the reception will be under a tent."
                justify={true}
            />
            <QuestionsElement
                question="Will there be an open bar?"
                answer="Please note that our wedding will be alcohol-free. Emory is the master of mocktails and is excited to present fun non-alcoholic drinks at our wedding."
                justify={true}
            />
            <QuestionsElement
                question="Can I bring a plus one?"
                answer="While we wish we could invite everyone, due to our venue’s capacity, our guest list is limited to our closest family members, friends, and some plus ones. Plus ones are indicated on the RSVP page, but if we missed anyone, please let us know."
                justify={true}
            />
            <QuestionsElement
                question="Are children welcome?"
                answer="We love your little ones, and they are absolutely welcome to attend, but feel free to make it a parent-only event if you prefer!"
                justify={true}
            />
            <QuestionsElement
                question="Can I take pictures during the ceremony?"
                answer="We've hired amazing photographers and videographers to fully capture our ceremony. We kindly request that you are fully present with us during our ceremony and refrain from taking photos and videos on your personal devices. We will share the professional photos and videos with all of our guests when they become available. Feel free to take photos and videos during the reception!"
                justify={true}
            />
            <QuestionsElement
                question="When is the RSVP deadline?"
                answer="Please let us know via the RSVP page on this site by August 23, 2023. We hope you can join us!"
                justify={false}
            />
        </div>
    )
}

export default Questions;