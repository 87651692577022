function MusicDetail(props) {
    return (
        <div style={{"width": "80%", "margin": "auto"}}>
            <div style={{"width": "100%", "margin": "auto"}}>
                What music will get you to dance?
            </div>
            <input
                style={{"width": "100%", "margin": "auto"}}
                type="text"
                placeholder="Optional"
                value={ props.rsvpsToChange[parseInt(props.index)]["music"]}
                onChange={props.handleChange("music", parseInt(props.index))}
            />
            <br/>
            { props.error ? 
                <div></div>
                : ""
            }
            <br/>
            <button onClick={ props.prevStep }>Prev</button>
            <button onClick={ props.nextStep }>Next</button>
        </div>
    )
}

export default MusicDetail;