function Registry() {
    return (
        <div className="registry">
            <h2>Registry</h2>
            <br/>
            <h2>Your presence is our favorite present!</h2>
            <div style={{textAlign: "justify"}}>Please do not feel obligated to provide us any gifts. We know many of you are traveling far to be with us on our wedding day, and that is the best present. However, if you insist on gifting, we would appreciate contributions to the following funds as we start our married life!</div>
            <br/>
            <button>
                <a style={{color: "inherit"}} href='https://www.zola.com/registry/adamandemory'>Registry on Zola</a>
            </button>
        </div>
    )
}

export default Registry;