//import { google } from "googleapis"

function FullNameDetail(props) {
    const ACCESS_TOKEN = "AIzaSyD4VLay1ngkwa3PHLaeOvEWi665Z0xvHpQ"
    const SHEET_ID = "1u06f_pdduZQgoCDnNwezjdm01nf5yDZJEJ6pv-P0e64"

    //const fs = require('fs').promises;
    //const path = require('path');
    //const process = require('process');
    //const {authenticate} = require('@google-cloud/local-auth');
    //const {google} = require('googleapis');

    

    return (
        <div>
            <label>
                First Name:
                <input
                    type="text"
                    placeholder="First Name"
                    value={ props.firstName }
                    onChange={props.handleChange("firstName")}
                />
            </label>
            <br/>
            <br/>
            <label>
                Last Name:
                <input
                    type="text"
                    placeholder="Last Name"
                    value={ props.lastName }
                    onChange={props.handleChange("lastName")}
                />
            </label>

            <br/>
            <br/>
            <button onClick={ props.nextStep }>Next</button>
            <br/>
            { props.error ? 
                <div>Please enter your name as spelled on the attachment in the invitation email and contact adamandemory@gmail.com if you still have trouble or have any name updates.</div>
                : ""
            }
            <br/>
        </div>
    )
}

export default FullNameDetail;
