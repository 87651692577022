function QuestionsElement(props) {
    const answerStyle = {
        margin: "auto",
        textAlign: "center",
        padding: "10px"
    }
    if (props.justify) {
        answerStyle["textAlign"] = "justify"
        answerStyle["textAlignLast"] = "left"
        answerStyle["hyphens"] = "auto"
        answerStyle["wordSpacing"] = "-0.05em"
        answerStyle["textJustify"] = "distribute"
    }
    return (
        <div style={{padding: "10px", margin: "auto"}}>
            <h3>{props.question}</h3>
            <div style={answerStyle}>
                {props.answer}
            </div>
            <br/>
            <hr style={{padding:"0px", height:"2px"}}></hr>
            <br/>
        </div>
    )
}

export default QuestionsElement;