import Form from 'react-bootstrap/Form';

function RSVPDetail(props) {
    return (
        <div style={{"width": "80%", "margin": "auto"}}>
            {props.rsvpsToChange.map(function(object, index) {
                console.log(parseInt(index))
                console.log(props.rsvpsToChange)
                console.log(props.rsvpsToChange[parseInt(index)]["dietary"])
                return <div style={{"padding": "10px", "margin": "auto"}}>
                    <hr style={{padding:"0px", height:"2px"}}></hr>
                    <div className="row justify-content-center">
                    <div style={{"width": "fit-content"}}>
                        { props.rsvpsToChange[parseInt(index)]["formatName"] }
                    </div>
                    <Form.Select
                        style={{"width": "fit-content", "marginLeft": "10px"}}
                        onChange={props.handleChange("rsvp", parseInt(index))}
                        value= {props.rsvpsToChange[parseInt(index)]["rsvp"]}
                    >
                        <option value="">Attending?</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </Form.Select>
                    <input
                        style={{"width": "500px", "marginLeft": "10px"}}
                        type="text"
                        placeholder="any dietary restrictions or mobility special needs"
                        value={ props.rsvpsToChange[parseInt(index)]["dietary"]}
                        onChange={props.handleChange("dietary", parseInt(index))}
                    />
                </div>
                </div>
            })}
            { props.error ? 
                <div>Please enter an attendance status for all attendees</div>
                : ""
            }
            <br/>
            <button onClick={ props.prevStep }>Prev</button>
            <button onClick={ props.nextStep }>Next</button>
        </div>
    )
}

export default RSVPDetail;