import Form from 'react-bootstrap/Form';

function PlusOneDetail(props) {
    return (
        <div>
            <div>You can add {props.toAddPlusOnes - props.rsvpsToAdd.length} plus ones</div>
            <br/>
            { props.rsvpsToAdd.length < props.toAddPlusOnes
                ? <button onClick={ props.addPlusOne }>+</button>
                : ""}
            <br/>
            {props.rsvpsToAdd.map(function(object, index) {
                return <div style={{"padding": "10px", "margin": "auto"}}>
                        <hr style={{padding:"0px", height:"2px"}}></hr>
                        <div className="row justify-content-center">
                        <input
                            style={{"width": "fit-content"}}
                            type="text"
                            placeholder="First & Last Name"
                            value={ props.rsvpsToAdd[parseInt(index)]["formatName"]}
                            onChange={props.handleChange("plusOneName", parseInt(index))}
                        />
                        <Form.Select
                            style={{"width": "fit-content", "marginLeft": "10px"}}
                            onChange={props.handleChange("plusOneRsvp", parseInt(index))}
                            value={props.rsvpsToAdd[parseInt(index)]["rsvp"]}
                            defaultValue="yes"
                            disabled={true}
                        >
                            <option value="">Can they attend?</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </Form.Select>
                        <input
                            style={{"width": "500px", "marginLeft": "10px"}}
                            type="text"
                            placeholder="any dietary restrictions or mobility special needs"
                            value={ props.rsvpsToAdd[parseInt(index)]["dietary"]}
                            onChange={props.handleChange("plusOneDietary", parseInt(index))}
                        />
                        <button style={{"width": "fit-content", "marginLeft": "10px"}} onClick={ props.removePlusOne(index) }>X</button>
                    </div>
                </div>
            })}
            { props.error ? 
                <div>Please enter a name for all added plus ones</div>
                : ""
            }
            <br/>
            <button onClick={ props.prevStep }>Prev</button>
            <button onClick={ props.nextStep }>Next</button>
        </div>
    )
}

export default PlusOneDetail;