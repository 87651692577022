import Form from 'react-bootstrap/Form';

function HotelDetail(props) {
    return (
        <div style={{"padding": "10px", "margin": "auto"}}>
            <div style={{"width": "100%"}}>
                Do you or anyone in your party plan on staying in our hotel block at the Hampton Inn?
            </div>
            <Form.Select
                style={{"width": "fit-content", "margin": "auto"}}
                onChange={props.handleChange("hotel", parseInt(props.index))}
                value= {props.rsvpsToChange[parseInt(props.index)]["hotel"]}
            >
                <option value="">Hotel?</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
            </Form.Select>
            <br/>
            { props.error ? 
                <div>Please answer yes or no for the hotel confirmation</div>
                : ""
            }
            <br/>
            <button onClick={ props.prevStep }>Prev</button>
            <button onClick={ props.nextStep }>Next</button>
        </div>
    )
}

export default HotelDetail;